import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { AppContext } from '../misc/AppContext'
import Navigation from '../components/common/Navigation'
import { jumpUcLogin } from '../utils/ucService'
import { getMenuInfo, getAppCode } from '../service/apiService'

function Layout() {
  const [menuInfo, setMenuInfo] = useState({})

  const dispatch = useContextSelector(AppContext, (v) => v[1])

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line
  }, [])

  async function getUserInfo() {
    try {
      const userInfo = await window.newUcManager
        .getCurrentAccount()
        .getAccountInfo()

      dispatch((v) => v.set('userInfo', userInfo))

      if (userInfo) {
        const appCode = await getAppCode()
        if (appCode?.data?.open_value) {
          const menuInfo = await getMenuInfo(appCode?.data?.app_id)

          setMenuInfo(menuInfo?.data)
          dispatch((v) =>
            v
              .setIn(['urlConfig', 'openValue'], appCode?.data?.open_value)
              .setIn(['urlConfig', 'rbacTenantId'], appCode?.data?.rbac_tenant_id)
              .set('menuInfo', menuInfo?.data)
          )
        }
        
        
      }
    } catch (e) {
      console.error(e)
      jumpUcLogin()
    }
  }

  return (
    <div className="tw-layout">
      <div className="tw-main">
        <div className="tw-flex">
          <Navigation menuInfo={menuInfo} />
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Layout
