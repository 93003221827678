import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { AppContext } from '../../misc/AppContext'
import { jumpUcLogin } from '../../utils/ucService'

function UserProfile() {
  const [language, setLanguage] = useState(
    window.localStorage.getItem('language') || 'en'
  )
  const { t, i18n } = useTranslation()
  const userInfo = useContextSelector(AppContext, (v) => v[0].get('userInfo'))

  useEffect(() => {
    i18n.changeLanguage(language)
    document.documentElement.setAttribute('lang', language)
  }, [language, i18n])

  async function logout() {
    await window.newUcManager.logout()
    window.localStorage.clear()
    jumpUcLogin()
  }

  function changeLanguage(value) {
    setLanguage(value)
    window.localStorage.setItem('language', value)
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-border-t tw-border-teal tw-pt-5">
      <span>{userInfo?.real_name}</span>
      <span className="[&_span]:tw-cursor-pointer">
        <span
          onClick={() => changeLanguage('en')}
          className={language === 'en' ? 'tw-text-teal' : ''}
        >
          EN
        </span>{' '}
        /
        <span
          onClick={() => changeLanguage('th')}
          className={language === 'th' ? 'tw-text-teal' : ''}
        >
          ภาษาไทย
        </span>
      </span>
      <span onClick={logout} className="tw-cursor-pointer hover:tw-text-teal">
        {t('登出')}
      </span>
    </div>
  )
}

export default UserProfile
