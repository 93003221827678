

export async function loadCustomUcSDK() {
  return new Promise((resolve, reject) => {
    const config = {
      origins: {
        UC: window.config.uc_api_host,
        SSO: window.config.uc_api_sso
      },
      storageExpire: -1 // Token存储在sessionStorage中
    }

    window.newUcManager = new window.SDP.UC.UC(config)
    resolve()
  })
}

export function jumpUcLogin() {
  const currentHost = window.config.current_page
  let url = `${currentHost}/jump?__redirect_uri=admin&__from_uc_login=true`
  url += `&change_env=${window.config.env}`
  let ucUrl = `${window.config.uc_login_page}/?sdp-app-id=${window.config.uc_login_sdp_app_id}#/login?redirect_uri=${encodeURIComponent(url)}&send_uckey=true&re_login=true`
  window.location.href = ucUrl
}

// 验证是否登录
export const isLogin = async () => {
  let _isLogin = true
  if (!window.newUcManager) {
    _isLogin = false
  }
  try {
    _isLogin = await window.newUcManager.isLogin()
  } catch (e) {
    _isLogin = false
  }
  return _isLogin
}

export const getUserId = () => {
  const token = window.newUcManager.getToken()
  let userId = token.account_id
  if (
    token.account_id === null ||
    token.account_id === undefined ||
    token.account_id === 0 ||
    token.account_id === '0'
  ) {
    userId = token.user_id
  }
  return userId
}
