// import Loadable from 'react-loadable'
import { base64_encode } from '../service/base64'
import { useSearchParams } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'
import { AppContext } from '../misc/AppContext'

function OuterFrame() {
  const [searchParams] = useSearchParams()
  let url = searchParams.get('url')
  const tenantId = searchParams.get('tenant_id')
  const name = searchParams.get('name')

  const urlConfig = useContextSelector(AppContext, (v) => v[0].get('urlConfig'))
  const userInfo = useContextSelector(AppContext, (v) => v[0].get('userInfo'))
  
  if (name === 'RBAC系统设置') {
    url += `/#/setting?mode=embed&back=setting`
  }
  if (name === '角色用户设置') {
    url += `/#/roleUser?mode=embed&back=roleUser`
  }
  if (name === '角色权限设置') {
    url += `/#/userrole?mode=embed&back=userroler`
  }

  url += `${url.indexOf('?') > -1 ? '&' : '?'}app_id=${urlConfig.get(
    'openValue'
  )}&sdp-app-id=${urlConfig.get('openValue')}`

  url += `&sdp-user-id=${userInfo?.user_id}`
  url += `&__tenant_id=${tenantId}`
  url += `&__rbac_tenant_id=${urlConfig.get('rbacTenantId')}`
  url += `&__menuItemName=${encodeURIComponent(name)}`
  url += '&__admin_manage=true'
  url += '&sdp_migrated=false&SDP-MIGRATED=false'
  url += `&__env=${window.config.env}`

  if (name === '试卷管理') {
    url += '&sdp-library-scope-type=paper'
  }

  const language = window.localStorage.getItem('language') || 'en'
  url += `&__lang=${language}&lang=${language}&locale=${language}`

  const mac = window.newUcManager.getAuthHeader({ url, method: 'GET' })
  let macKey = '__mac'
  if (/elearning-study-library-gateway|role-admin/.test(url)) {
    macKey = 'auth'
  }
  url += `&${macKey}=` + encodeURIComponent(base64_encode(mac))

  

  return (
    <div className="tw-w-full tw-h-[100vh]">
      {urlConfig.get('openValue') && (
        <iframe
          title="iframe-content"
          id="iframe-content"
          className="tw-w-full tw-h-full"
          allowFullScreen
          src={url}
        />
      )}
    </div>
  )
}

export default OuterFrame
