import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


function Home() {
  const { t } = useTranslation()

  return <div className="tw-p-8">dashboard content</div>
}

export default Home
