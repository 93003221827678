import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UserProfile from './UserProfile'
import classNames from 'classnames'

const _url = {
  频道管理: `${window.config.console_url}/channel`,
  频道内容: `${window.config.console_url}/channel/0/layout/content`,
  学习内容库: `${window.config.elearning_study_library_gateway_url}/admin/index`,
  题库管理: `${window.config.e_question_bank_url}/admin/question_bank`,
  试卷管理: `${window.config.elearning_study_library_gateway_url}/admin/index`,
  证书管理: `${window.config.auxo_certificate_gateway_url}/admin/manage/index`,
  证书寄送: `${window.config.auxo_certificate_gateway_url}/admin/send`,
  证书生成: `${window.config.auxo_certificate_gateway_url}/admin/generate/index`,
  RBAC系统设置: window.config.role_admin_url,
  角色用户设置: window.config.role_admin_url,
  角色权限设置: window.config.role_admin_url,
}

function Navigation({ menuInfo }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [active, setActive] = useState(searchParams.get('name') || '')

  function goIframe(name, tenantId) {
    setActive(name)
    navigate(
      `/admin/iframe?url=${_url[name]}&name=${name}&tenant_id=${tenantId}`
    )
  }

  return (
    <nav className="tw-px-8 tw-text-white tw-bg-dark-teal tw-text-[14px] tw-py-8 tw-shrink-0 tw-w-[280px] tw-h-[100vh] tw-overflow-y-auto">
      <img src="/aom-logo-teal.svg" alt="aom-ai" />
      <ul className="tw-flex tw-flex-col tw-gap-4 tw-mt-8">
        <li
          className={classNames(
            'tw-text-light-teal tw-cursor-pointer',
            !active ? 'tw-text-light-teal tw-font-bold' : ''
          )}
          onClick={() => navigate('/admin')}
        >
          {t('首页')}
        </li>
        {menuInfo.items?.map((item, index) => {
          if (item.name === '社交' || item.name === '沟通') {
            return null
          }
          return (
            <li key={index} className="">
              {t(item.name)}
              {item.children?.map((subItem) => {
                if (
                  subItem.name === '通用权限管理' ||
                  subItem.name === '学习管理中心' ||
                  subItem.name === '内容中心' ||
                  subItem.name === '教务中心'
                ) {
                  return (
                    <li className="tw-pl-4 tw-mb-4 tw-leading-4 ">
                      {t(subItem.name)}
                      {subItem.children?.map((subsubItem) => {
                        if (
                          subsubItem.name === 'RBAC可见性设置' ||
                          subsubItem.name === '身份类型与角色'
                        ) {
                          return null
                        }
                        if (subsubItem.name === '证书管理') {
                          return (
                            <li className="tw-pl-4 tw-my-3">
                              {t(subsubItem.name)}
                              {subsubItem.children?.map((subsubsubItem) => (
                                <li
                                  onClick={() =>
                                    goIframe(
                                      subsubsubItem.name,
                                      subsubsubItem.tenant_id
                                    )
                                  }
                                  className={classNames(
                                    'tw-p-2 tw-my-3 tw-leading-4 tw-cursor-pointer tw-text-light-teal hover:tw-text-teal',
                                    {'tw-border-b tw-border-light-teal': active === subsubsubItem.name}
                                  )}
                                >
                                  {t(subsubsubItem.name)}
                                </li>
                              ))}
                            </li>
                          )
                        }
                        return (
                          <li
                            onClick={() =>
                              goIframe(subsubItem.name, subsubItem.tenant_id)
                            }
                            className={classNames(
                              'tw-p-2 tw-my-3 tw-leading-4 tw-cursor-pointer tw-text-light-teal hover:tw-text-teal',
                              {'tw-border-b tw-border-light-teal': active === subsubItem.name}
                            )}
                          >
                            {t(subsubItem.name)}
                          </li>
                        )
                      })}
                    </li>
                  )
                }
                return null
              })}
            </li>
          )
        })}
      </ul>

      <UserProfile />
    </nav>
  )
}

export default Navigation
