import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import EnvSelector from '../components/common/EnvSelector'
import { jumpUcLogin, isLogin } from '../utils/ucService'

function Landing() {
  const navigate = useNavigate()
  useEffect(() => {
    loginStatus()
    // eslint-disable-next-line
  }, [])

  async function loginStatus() {
    if (process.env.NODE_ENV !== 'development') {
      if (await isLogin()) {
        navigate('/admin')
      } else {
        jumpUcLogin()
      }
    }
  }

  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  return (
    <div className="tw-flex tw-items-center tw-justify-center">
      <EnvSelector />
    </div>
  )
}

export default Landing
