import { useState } from 'react'
import { fromJS, Map } from 'immutable'
import { createContext } from 'use-context-selector'

// initial state
export const initialState = fromJS({
  userInfo: null,
  urlConfig: {
    openValue: '',
    rbacTenantId: '',
  },
  menuInfo: null,
})

// provider
const AppContext = createContext(Map())

function AppProvider({
  children, state = initialState,
}) {
  return (
    <AppContext.Provider value={useState(state)}>
      {children}
    </AppContext.Provider>
  )
}

export {
  AppContext, AppProvider
}




