const env =
  window.__global_env || window.localStorage.getItem('env') || 'aom-ai-beta'

const ENV_CONFIG = {
  'aom-ai-beta': {
    NODE_ENV: '"aom-ai-beta"',
    env: 'aom-ai-beta',
    base_url: '//manage-portal-api.th2-staging.apse1.ndpg.xyz',
    uc_api_host: '//uc-gateway.th2-staging.apse1.ndpg.xyz',
    uc_api_sso: '//uc-sso.th2-staging.apse1.ndpg.xyz',
    app_host: '//portal-app-server.th2-staging.apse1.ndpg.xyz',
    portal_service_host: '//portal-service-mng.th2-staging.apse1.ndpg.xyz',
    rbac_host: '//rbac.th2-staging.apse1.ndpg.xyz',
    avatar_host: '//uc-gateway.th2-staging.apse1.ndpg.xyz',
    cs_host: '//sdpcs.th2-staging.apse1.ndpg.xyz',
    cs_service_name: 'aom_ai_thai_management_portal',
    logo_host: 'https://cdncs.th2-staging.apse1.ndpg.xyz',
    white_domain_list:
      'https://gcdncs.101.com/v0.1/static/manage_portal_web/notUnified.json?t=2',
    sdp_app_id: '85c1e34f-5d50-41bf-a977-b562c1a79d79',
    biz_host: 'https://portal-biz-service.th2-staging.apse1.ndpg.xyz',
    language_host: 'https://app-factory-translate.th2-staging.apse1.ndpg.xyz',
    uc_login_page: 'https://uc-component.th2-staging.apse1.ndpg.xyz',
    portal_web_domain_manage:
      'https://portal-web-domain-manage.th2-staging.apse1.ndpg.xyz',
    cdnHost: '/assets/',
    current_page: 'https://manage-portal-web.th2-staging.apse1.ndpg.xyz',
    uc_login_sdp_app_id: '85c1e34f-5d50-41bf-a977-b562c1a79d79',
    app_code: 'ap1726713405451',
    console_url: 'https://console.th2-staging.apse1.ndpg.xyz',
    elearning_study_library_gateway_url: 'https://elearning-study-library-gateway.th2-staging.apse1.ndpg.xyz',
    e_question_bank_url: 'https://e-question-bank.th2-staging.apse1.ndpg.xyz',
    auxo_certificate_gateway_url: 'https://auxo-certificate-gateway.th2-staging.apse1.ndpg.xyz',
    role_admin_url: 'https://role-admin.th2-staging.apse1.ndpg.xyz',
  },
  'aom-ai': {
    NODE_ENV: '"aom-ai"',
    env: 'aom-ai',
    base_url: '//manage-portal-api.prod.aom-ai.com',
    uc_api_host: '//uc-gateway.prod.aom-ai.com',
    uc_api_sso: '//uc-sso.prod.aom-ai.com',
    app_host: '//portal-app-server.prod.aom-ai.com',
    portal_service_host: '//portal-service-mng.prod.aom-ai.com',
    rbac_host: '//rbac.prod.aom-ai.com',
    avatar_host: '//uc-gateway.prod.aom-ai.com',
    cs_host: '//sdpcs.prod.aom-ai.com',
    cs_service_name: 'aomai_prod_management_portal',
    logo_host: 'https://cdncs.prod.aom-ai.com',
    white_domain_list:
      'https://gcdncs.101.com/v0.1/static/manage_portal_web/notUnified.json?t=2',
    sdp_app_id: '85c1e34f-5d50-41bf-a977-b562c1a79d79',
    biz_host: 'https://portal-biz-service.prod.aom-ai.com',
    language_host: 'https://app-factory-translate.prod.aom-ai.com',
    uc_login_page: 'https://uc-component.prod.aom-ai.com',
    portal_web_domain_manage:
      'https://portal-web-domain-manage.prod.aom-ai.com',
    cdnHost: '/assets/',
    current_page: 'https://manage-portal-web.prod.aom-ai.com',
    uc_login_sdp_app_id: '85c1e34f-5d50-41bf-a977-b562c1a79d79',
    app_code: 'ap1726713405451',
    console_url: 'https://console.prod.aom-ai.com',
    elearning_study_library_gateway_url: 'https://elearning-study-library-gateway.prod.aom-ai.com',
    e_question_bank_url: 'https://e-question-bank.prod.aom-ai.com',
    auxo_certificate_gateway_url: 'https://auxo-certificate-gateway.prod.aom-ai.com',
    role_admin_url: 'https://role-admin.prod.aom-ai.com',
  },
}

window.config = ENV_CONFIG[env]

if (process.env.NODE_ENV === 'development') {
  window.config.current_page = 'http://localhost:3000' // for local dev debug
}
