import React from 'react'
import ReactDOM from 'react-dom/client'
import './config/env'
import './misc/i18n'
import { AppProvider } from './misc/AppContext'
import App from './App'
import { loadCustomUcSDK } from './utils/ucService'

import './index.css'

loadCustomUcSDK().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <AppProvider>
      <App />
    </AppProvider>
  )
})
