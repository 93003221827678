import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

function Jump() {
 
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const uckey = searchParams.get('uckey')
    valid(uckey)
    // eslint-disable-next-line
  }, [searchParams])

  async function valid(uckey) {
    try {
      await window.newUcManager.loginByUCKey({ uckey })
      navigate('/admin')
    } catch (e) {
      console.error(e)
    }
  }

  return null
}

export default Jump
