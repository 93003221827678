import axios from 'axios'

export const getAppCode = () => {
  return axios.get(
    `${window.config.base_url}/v2/apps/code/${window.config.app_code}`,
    { show_org: true }
  )
}

// export const queryProductInfo = (sdpAppId) => {
//   return axios.get(`${window.config.biz_host}/v0.1/apps/ids/${sdpAppId}`, { unAuth: true })
// }

export const getMenuInfo = (appId) => {
  const url = `${window.config.base_url}/v2/apps/${appId}/app_menus/my`
  return axios.get(url, {
    headers: {
      Authorization: window.newUcManager.getAuthHeader({
        url,
        method: 'GET',
      }),
    },
  })
}
