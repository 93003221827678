import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Jump from './pages/Jump'
import Layout from './pages/Layout'
import Landing from './pages/Landing'
import Home from './pages/Home'
import OuterFrame from './pages/OuterFrame'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/jump" element={<Jump />} />
        <Route path="/admin" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="iframe" element={<OuterFrame />} />
        </Route>
        <Route path="*" element={<>404 page</>} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
